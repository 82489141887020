@import 'styles/utils/mixins';
@import 'styles/utils/_shared';

@keyframes loader {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(100%);
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-size-5);

  & :last-child {
    margin-bottom: 0;
  }
}

.titleContainer {
  .title {
    animation-name: loader;
    background-color: var(--color-surface-02);
    height: var(--space-size-4);
    width: 30.5rem;
    margin-bottom: 1.2rem;
    position: relative;
    overflow: hidden;
    border-radius: 0.2rem;

    @media screen and (min-width: $breakpoint-lg) {
      height: 3.2rem;
    }

    &::after {
      @include animationLoading(1.5s, 0.5s);
    }
  }

  .subtitle {
    height: var(--space-size-2);
    width: 17.7rem;
    background-color: var(--color-surface-02);
    position: relative;
    overflow: hidden;
    border-radius: 0.2rem;
    margin-bottom: 2.8rem;

    @media screen and (min-width: $breakpoint-lg) {
      height: 1rem;
    }

    &::after {
      @include animationLoading(1s, 0.8s);
    }
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & :nth-child(7),
  & :nth-child(8) {
    display: none;
  }

  @media screen and (min-width: $breakpoint-lg) {
    flex-wrap: nowrap;

    & :nth-child(7),
    & :nth-child(8) {
      display: block;
    }
  }
}

.card {
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
  margin-bottom: var(--space-size-2);
  opacity: 0.85;

  @media screen and (min-width: $breakpoint-md) {
    margin-right: var(--space-size-2);
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin-bottom: 0;
  }
}

.cardImage {
  position: relative;
  width: 15.2rem;
  aspect-ratio: 0.8;
  margin-bottom: 0.8rem;
  background-color: var(--color-surface-02);
  overflow: hidden;
  border-radius: 0.8rem;

  @media screen and (min-width: $breakpoint-lg) {
    width: 19.5rem;
  }

  &::after {
    @include animationLoading(1.5s, 0s);
  }
}

.cardTitle {
  animation-name: loader;
  background-color: var(--color-surface-02);
  height: var(--space-size-1);
  width: 95%;
  margin-bottom: var(--space-size-1);
  position: relative;
  overflow: hidden;
  border-radius: 0.2rem;

  @media screen and (min-width: $breakpoint-lg) {
    height: 1.2rem;
  }

  &::after {
    @include animationLoading(1.5s, 0.5s);
  }
}

.cardSubtitle {
  height: 0.6rem;
  width: 65%;
  background-color: var(--color-surface-02);
  position: relative;
  overflow: hidden;
  border-radius: 0.2rem;

  @media screen and (min-width: $breakpoint-lg) {
    height: 1rem;
  }

  &::after {
    @include animationLoading(1s, 0.8s);
  }
}
