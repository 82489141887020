@import 'styles/utils/mixins';
@import 'styles/core/typography';

.container {
  background-color: var(--color-surface-01);
  padding: 4rem 2.4rem 4rem 2.4rem;
  border-radius: 0.8rem;
  @include grid;
}

.links {
  color: var(color-on-bg-primary);
  grid-column: 1 / span 6;
  width: 100%;
  font-size: 2.4rem;
  letter-spacing: 0.06em;
  padding-top: 2rem;

  @media screen and (min-width: $breakpoint-sm) {
    display: flex;
    flex-direction: row;
  }

  ul {
    @media screen and (min-width: $breakpoint-sm) {
      display: flex;
    }
  }
}

.title {
  color: var(--color-on-bg-secondary);
  grid-column: 1 / span 6;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  @include label-m-medium;
  @media screen and (min-width: $breakpoint-sm) {
    grid-column: 1 / span 12;
  }
}

.item {
  display: flex;
  cursor: pointer;
  color: inherit;
  grid-column: 1 / span 6;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  margin-right: 4.2rem;
  white-space: nowrap;
  justify-content: space-between;
  @media screen and (min-width: $breakpoint-sm) {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}
.link {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  display: block;
  @include heading-m-medium;
}
.arrowIcon {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 1.6rem;
  @media screen and (min-width: $breakpoint-lg) {
    width: 2.4rem;
    height: 2.4rem;
  }
}
