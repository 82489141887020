@import 'styles/utils/_shared';
@import 'styles/utils/mixins';

.container {
  background-color: var(--color-surface-01);
  padding-top: var(--space-size-8);
  padding-bottom: var(--space-size-8);
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &.paddingContainer {
    @include container;
    padding-top: var(--space-size-8);
    padding-bottom: var(--space-size-8);
  }
}
