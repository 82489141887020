@import 'styles/utils/mixins';
@import 'styles/core/typography';
.container {
  /*  max-width: 26rem; */
  overflow-x: scroll;
  @include hide-scrollbar;
  border-bottom: 0.1rem solid var(--color-divider-primary);
  @media screen and (min-width: $breakpoint-sm) {
    width: 100%;
  }
}

.list {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  transition: color 0.2s;
  padding-right: var(--space-size-2);
  padding-left: var(--space-size-2);

  &.noPaddingList {
    padding: 0;
  }
  &.smallPadding {
    margin-left: 0;
    padding-left: var(--space-size-2);
  }

  @media screen and (min-width: $breakpoint-sm) {
    padding-left: var(--space-size-4);
    padding-left: var(--space-size-4);
  }

  @media screen and (min-width: $breakpoint-lg) {
    padding-right: var(--space-size-5);
  }
}

.item {
  position: relative;
  //height: 100%;
  padding: 1.6rem 0.8rem 0rem;
  margin-right: 1rem;
  white-space: nowrap;
  height: 5.6rem;
  &:hover {
    @extend .itemActive;
  }
  &::before {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background-color: var(--color-on-bg-primary);
    transition: height 0.2s;
  }
  &.smallFont {
    height: auto;
    margin-right: 0;
    margin: 0 var(--space-size-2) 0 0;
    padding: 1.4rem 0;
    // @include label-s-bold;
    text-transform: uppercase;
    @media screen and (min-width: $breakpoint-sm) {
      margin: 0 var(--space-size-2) 0 0;
    }
    a {
      @include label-s-bold;
    }
  }
}
.link {
  text-decoration: none;
  display: block;
}
a.link {
  cursor: pointer;
}
.itemActive {
  @include body-bold;
  // &.smallFont {
  //   a {
  //     @include label-s-bold;
  //   }
  // }
  a {
    color: var(--color-on-bg-primary);
    transition: color 0.2s;
  }

  &::before {
    height: 0.2rem;
    transition: height 0.2s;
  }
}
.itemDisable {
  a {
    color: var(--color-on-bg-secondary);
    transition: color 0.2s;
  }
  &::before {
    height: 0rem;
    transition: height 0.2s;
  }
}

.menuMyBets {
  border-bottom: unset;
  .list {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: color 0.2s;
    padding-right: var(--space-size-2);
    padding-left: var(--space-size-2);

    &.noPaddingList {
      padding: 0;
    }

    @media screen and (min-width: $breakpoint-sm) {
      padding-left: var(--space-size-4);
      padding-left: var(--space-size-4);
    }

    @media screen and (min-width: $breakpoint-lg) {
      padding-right: var(--space-size-5);
    }
  }

  .item {
    position: relative;
    height: unset;
    padding: 1.6rem 0.8rem 1.6rem;
    margin-right: 1rem;
    white-space: nowrap;
    &:hover {
      a {
        color: var(--color-primary);
        transition: color 0.2s;
      }
      &::before {
        height: 0.4rem;
        transition: height 0.2s;
      }
    }
    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 0%;
      background-color: var(--color-primary);
      transition: height 0.2s;
    }
  }
  .link {
    text-decoration: none;
    display: block;
  }
  a.link {
    cursor: pointer;
    @include label-m-bold;
    text-transform: uppercase;
  }
  .itemActive {
    a {
      color: var(--color-primary);
      transition: color 0.2s;
    }
    &::before {
      height: 0.4rem;
      transition: height 0.2s;
    }
  }
  .itemDisable {
    a {
      color: var(--color-on-bg-secondary);
      transition: color 0.2s;
    }
    &::before {
      height: 0rem;
      transition: height 0.2s;
    }
  }
}
